/* eslint-disable no-dupe-keys */
export const measurements = [
  { value: "g", name: "Gramme (g)" },
  { value: "kg", name: "Kilogramme (kg)" },
  { value: "l", name: "Litre (l)" },
  { value: "m3", name: "Cubic metre (m3)" },
  { value: "sqm", name: "Square metre (sqm)" },
  { value: "truck", name: "Truck" },
  { value: "ton", name: "Ton (ton)" },
  { value: "ml", name: "Millilitre (ml)" },
  { value: "mm", name: "Millimetre (mm)" },
  { value: "bag", name: "Bag" },
  { value: "drum", name: "Drum" },
  { value: "others", name: "Others" },
];

export const AppCodes = {
  oxide: process.env.VUE_APP_OXIDE_APP_CODE,
  orbital: process.env.VUE_APP_ORBITAL_APP_CODE,
  flux: process.env.VUE_APP_FLUX_APP_CODE,
  matta: process.env.VUE_APP_MATTA_APP_CODE,
  mattapedia: process.env.VUE_APP_MATTAPEDIA_APP_CODE,
};

export const FinancesOptions = [
  {
    title: "trade finance",
    img: "/images/finance1.png",
    text: "How do you create compelling presentations that wow your colleagues and impress your managers?",
    url: "/financing/requests/trade/0",
  },
  {
    title: "inventory finance",
    img: "/images/finance2.png",
    text: "How do you create compelling presentations that wow your colleagues and impress your managers?",
    url: "/financing/requests/supply/1",
  },
  {
    title: "import finance",
    img: "/images/finance3.png",
    text: "How do you create compelling presentations that wow your colleagues and impress your managers?",
    url: "/financing/requests/import/2",
  },
  {
    title: "export finance",
    img: "/images/finance4.png",
    text: "How do you create compelling presentations that wow your colleagues and impress your managers?",
    url: "/financing/requests/export/3",
  },
];

export const buyerRoutes = [
  "account-settings",
  "procurement-my-orders",
  "procurement-my-requests",
  "wallet-home",
  "procurement-shipping-addresses",
  "financing",
  "account-saved-searches",
  // "profile",
  "sign-out",
];
export const vendorRoutes = [
  "overview",
  "account-settings",
  "procurement-my-orders",
  "procurement-my-requests",
  "wallet-home",
  "procurement-shipping-addresses",
  "financing",
  "account-saved-searches",
  "products",
  "storefront",
  "storefront-orders",
  "storefront-requests",
  "user-managemennt",
  "company-settings",
  // "profile",
  "sign-out",
];

export const MattaNavigation = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "mingcute:layout-3-line",
    key: "dashboard",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Products",
    url: "/products",
    icon: "fluent-mdl2:product-variant",
    key: "products",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Campaign",
    url: "/campaign",
    icon: "material-symbols:campaign-outline-rounded",
    key: "campaign",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },

  {
    name: "Procurement",
    url: "/procurement/my-orders",
    icon: "lucide:shopping-bag",
    key: "procurement-my-orders",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },

  {
    name: "Financing requests",
    url: "/financing",
    icon: "heroicons:tag",
    key: "financing",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Payouts & Settlements",
    url: "/payment-management",
    icon: "material-symbols:payments-outline-rounded",
    key: "payment-management",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Currency management",
    url: "/currency-management",
    icon: "fluent-mdl2:all-currency",
    key: "currency",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Orders management",
    url: "/orders",
    icon: "solar:shop-outline",
    key: "orders",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Shipping management",
    url: "/shipping-management",
    icon: "la:shipping-fast",
    key: "shipping-management",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Requests",
    url: "/requests",
    icon: "icon-park-outline:pull-requests",
    key: "requests",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Markets & Applications",
    url: "/market-settings",
    icon: "mingcute:building-5-line",
    key: "market-settings",
    roles: ["superadmin", "superuser", "admin", "sales", "commercial"],
  },

  {
    name: "Customers",
    url: "/customers",
    icon: "lucide:users",
    key: "user-management",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Wallets",
    url: "/wallets",
    icon: "lucide:wallet",
    key: "wallet-management",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Agents",
    url: "/agents",
    icon: "lucide:users",
    key: "agents-management",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Administrators",
    url: "/administrators",
    icon: "ic:outline-admin-panel-settings",
    key: "administrators",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Audit Logs",
    url: "/logs",
    icon: "icon-park-outline:upload-logs",
    key: "logs",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Settings",
    url: "/settings",
    icon: "fa:cog",
    key: "logs",
    roles: ["superadmin", "superuser", "admin"],
  },
];

export const OxideNavigation = [
  {
    name: "Home",
    url: "/dashboard",
    icon: "mingcute:layout-3-line",
    key: "dashboard",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },

  {
    name: "Financing",
    url: "/financing",
    icon: "heroicons:tag",
    key: "financing",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },
  {
    name: "Wallets",
    url: "/wallets",
    icon: "lucide:wallet",
    key: "wallet-management",
    roles: ["superadmin", "superuser", "admin"],
  },
  {
    name: "Payouts & Settlements",
    url: "/payment-management",
    icon: "material-symbols:payments-outline-rounded",
    key: "payment-management",
    roles: [
      "superadmin",
      "superuser",
      "admin",
      "sales",
      "commercial",
      "finance",
      "agent",
    ],
  },

  {
    name: "Settings",
    url: "/settings",
    icon: "fa:cog",
    key: "logs",
    roles: ["superadmin", "superuser", "admin"],
  },
];
export const OribitalNavigation = [];
export const FluxNavigation = [
  {
    name: "Home",
    url: "/dashboard",
    icon: "mingcute:layout-3-line",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Drivers Management",
    url: "/flux/drivers-management",
    icon: "healthicons:truck-driver-outline",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Vehicles Management",
    url: "/flux/vehicles-management",
    icon: "lucide:truck",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Users Management",
    url: "/flux/users-management",
    icon: "solar:users-group-rounded-linear",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Payments Management",
    url: "/flux/payments-management",
    icon: "hugeicons:credit-card-pos",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Orders Management",
    url: "/flux/orders-management",
    icon: "solar:box-broken",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
  {
    name: "Administrators",
    url: "/flux/administrators",
    icon: "material-symbols:admin-panel-settings-outline-rounded",
    key: "dashboard",
    roles: ["superadmin", "admin", "companyadmin"],
  },
];
export const businessTypes = [
  {
    sector: "Agriculture",
    sectorCode: "40100",
    subSectors: [
      {
        subSectorName: "Crop Production",
        subSectorCode: "40110",
      },
      {
        subSectorName: "Poultry and livestock",
        subSectorCode: "40120",
      },
      {
        subSectorName: "Fishing",
        subSectorCode: "40130",
      },
      {
        subSectorName: "Plantation",
        subSectorCode: "40140",
      },
      {
        subSectorName: "Agro Services",
        subSectorCode: "40150",
      },
      {
        subSectorName: "Cash Crop",
        subSectorCode: "40160",
      },
    ],
  },
  {
    sector: "Mining and Quarrying",
    sectorCode: "40200",
    subSectors: [
      {
        subSectorName: "Metal Tin, Iron, etc",
        subSectorCode: "40210",
      },
      {
        subSectorName: "Non-metal Quarrying",
        subSectorCode: "40220",
      },
      {
        subSectorName: "Others",
        subSectorCode: "40230",
      },
    ],
  },
  {
    sector: "Manufacturing",
    sectorCode: "40300",
    subSectors: [
      {
        subSectorName: "Flourmills and Bakeries",
        subSectorCode: "40301",
      },
      {
        subSectorName: "Food manufacturing",
        subSectorCode: "40302",
      },
      {
        subSectorName: "Beverages",
        subSectorCode: "40303",
      },
      {
        subSectorName: "Aluminium and allied products",
        subSectorCode: "40304",
      },
      {
        subSectorName: "Basic Metal Products",
        subSectorCode: "40305",
      },
      {
        subSectorName: "Breweries",
        subSectorCode: "40306",
      },
      {
        subSectorName: "Building materials",
        subSectorCode: "40307",
      },
      {
        subSectorName: "Cement",
        subSectorCode: "40308",
      },
      {
        subSectorName: "Chemicals and Allied products",
        subSectorCode: "40309",
      },
      {
        subSectorName: "Footwear",
        subSectorCode: "40310",
      },
      {
        subSectorName: "Hides and Skin",
        subSectorCode: "40311",
      },
      {
        subSectorName: "Household Equipment",
        subSectorCode: "40312",
      },
      {
        subSectorName: "Pharmaceuticals",
        subSectorCode: "40313",
      },
      {
        subSectorName: "Paints and Allied products",
        subSectorCode: "40314",
      },
      {
        subSectorName: "Miscellaneous Manufacturing",
        subSectorCode: "40315",
      },
      {
        subSectorName: "Paper and Paper products",
        subSectorCode: "40316",
      },
      {
        subSectorName: "Printing and Publishing",
        subSectorCode: "40317",
      },
      {
        subSectorName: "Personal care",
        subSectorCode: "40318",
      },
      {
        subSectorName: "Plastics",
        subSectorCode: "40319",
      },
      {
        subSectorName: "Rubber and Allied products",
        subSectorCode: "40320",
      },
      {
        subSectorName: "Steel Rolling Mills",
        subSectorCode: "40321",
      },
      {
        subSectorName: "Soft Drinks",
        subSectorCode: "40322",
      },
      {
        subSectorName: "Cables and Mines",
        subSectorCode: "40323",
      },
      {
        subSectorName: "Textiles and Apparel",
        subSectorCode: "40324",
      },
      {
        subSectorName: "Tyre",
        subSectorCode: "40325",
      },
      {
        subSectorName: "Conglomerate",
        subSectorCode: "40326",
      },
    ],
  },
  {
    sector: "Real Estate",
    sectorCode: "40500",
    subSectors: [
      {
        subSectorName: "Residential Mortgage Loans",
        subSectorCode: "40510",
      },
      {
        subSectorName: "Commercial Property",
        subSectorCode: "40520",
      },
      {
        subSectorName: "Home Equity",
        subSectorCode: "40530",
      },
      {
        subSectorName: "Real estate Construction/ Home Developers",
        subSectorCode: "40540",
      },
      {
        subSectorName: "Real estate (Income-Producing)",
        subSectorCode: "40550",
      },
      {
        subSectorName: "High-volatility Commercial real estate",
        subSectorCode: "40560",
      },
    ],
  },
  {
    sector: "Public Utilities",
    sectorCode: "40600",
    subSectors: [
      {
        subSectorName: "Utility (Public)",
        subSectorCode: "40610",
      },
      {
        subSectorName: "Utility (Private)",
        subSectorCode: "40620",
      },
    ],
  },
  {
    sector: "General Commerce",
    sectorCode: "40700",
    subSectors: [
      {
        subSectorName: "Automotive parts",
        subSectorCode: "40710",
      },
      {
        subSectorName: "Domestic trade (General Trading)",
        subSectorCode: "40720",
      },
      {
        subSectorName: "Automobile (Motor Vehicles)",
        subSectorCode: "40730",
      },
      {
        subSectorName: "Food Processing",
        subSectorCode: "40740",
      },
      {
        subSectorName: "Chemicals and Allied Products",
        subSectorCode: "40750",
      },
      {
        subSectorName: "Trading (Rice)",
        subSectorCode: "40760",
      },
      {
        subSectorName: "Trading (Cocoa)",
        subSectorCode: "40770",
      },
      {
        subSectorName: "Generator set (sales and services)",
        subSectorCode: "40780",
      },
    ],
  },
  {
    sector: "Transportation and Storage",
    sectorCode: "40800",
    subSectors: [
      {
        subSectorName: "Road transport",
        subSectorCode: "40810",
      },
      {
        subSectorName: "Water transport",
        subSectorCode: "40820",
      },
      {
        subSectorName: "Air transport",
        subSectorCode: "40830",
      },
      {
        subSectorName: "Warehousing and support activities for transportation",
        subSectorCode: "40840",
      },
      {
        subSectorName: "Postal and courier activities",
        subSectorCode: "40850",
      },
    ],
  },
  {
    sector: "Finance and Insurance",
    sectorCode: "40900",
    subSectors: [
      {
        subSectorName: "Commercial bank",
        subSectorCode: "40910",
      },
      {
        subSectorName: "Microfinance bank",
        subSectorCode: "40920",
      },
      {
        subSectorName: "Asset management",
        subSectorCode: "40930",
      },
      {
        subSectorName: "Mortgage institutions",
        subSectorCode: "40940",
      },
      {
        subSectorName: "Insurance companies",
        subSectorCode: "40950",
      },
      {
        subSectorName: "Pension Fund custodians",
        subSectorCode: "40960",
      },
      {
        subSectorName: "Pension Fund administrators",
        subSectorCode: "40970",
      },
      {
        subSectorName: "Stock broking firms",
        subSectorCode: "40980",
      },
      {
        subSectorName: "Other financial institutions",
        subSectorCode: "40990",
      },
      {
        subSectorName: "Mutual fund Administrators",
        subSectorCode: "40991",
      },
      {
        subSectorName: "Money and Value Transfer Operators",
        subSectorCode: "40992",
      },
      {
        subSectorName: "Bureau De Change",
        subSectorCode: "40993",
      },
    ],
  },
  {
    sector: "General",
    sectorCode: "41000",
    subSectors: [
      {
        subSectorName: "Hotel and leisure",
        subSectorCode: "41010",
      },
      {
        subSectorName: "Personal",
        subSectorCode: "41020",
      },
      {
        subSectorName: "Religious bodies",
        subSectorCode: "41030",
      },
      {
        subSectorName: "Retail (others)",
        subSectorCode: "41040",
      },
      {
        subSectorName: "Logistics",
        subSectorCode: "41050",
      },
      {
        subSectorName: "Political parties accounts",
        subSectorCode: "41060",
      },
      {
        subSectorName: "Jewellery and precious stone dealers",
        subSectorCode: "41070",
      },
      {
        subSectorName: "Association and Clubs",
        subSectorCode: "41080",
      },
      {
        subSectorName:
          "Non governmental organisation/ Not for profit org (NGOs/NPOs)",
        subSectorCode: "41090",
      },
    ],
  },
  {
    sector: "Government",
    sectorCode: "41200",
    subSectors: [
      {
        subSectorName: "Federal (Direct)",
        subSectorCode: "41210",
      },
      {
        subSectorName: "Federal (Parastatal)",
        subSectorCode: "41220",
      },
      {
        subSectorName: "State (Direct)",
        subSectorCode: "41230",
      },
      {
        subSectorName: "State (Parastatal)",
        subSectorCode: "41240",
      },
      {
        subSectorName: "Local (Direct)",
        subSectorCode: "41250",
      },
      {
        subSectorName: "Local (Parastatal)",
        subSectorCode: "41260",
      },
      {
        subSectorName: "Legislative arm of government",
        subSectorCode: "41270",
      },
      {
        subSectorName: "Judiciary arm of government",
        subSectorCode: "41280",
      },
      {
        subSectorName: "Executive arm of government",
        subSectorCode: "41290",
      },
    ],
  },
  {
    sector:
      "Water Supply; Sewerage, Waste Management and Remediation Activities",
    sectorCode: "41300",
    subSectors: [
      {
        subSectorName: "Water collection, treatment and supply",
        subSectorCode: "41310",
      },
      {
        subSectorName: "Sewerage",
        subSectorCode: "41320",
      },
      {
        subSectorName:
          "Waste collection, treatment and disposal activities; materials recovery",
        subSectorCode: "41330",
      },
      {
        subSectorName:
          "Remediation activities and other waste management services",
        subSectorCode: "41340",
      },
    ],
  },
  {
    sector: "Construction",
    sectorCode: "41400",
    subSectors: [
      {
        subSectorName: "Civil engineering",
        subSectorCode: "41410",
      },
      {
        subSectorName: "Specialized construction activities",
        subSectorCode: "41420",
      },
    ],
  },
  {
    sector: "Information and Communication",
    sectorCode: "41500",
    subSectors: [
      {
        subSectorName: "Publishing activities",
        subSectorCode: "41510",
      },
      {
        subSectorName:
          "Motion picture, video and television programme production, sound recording and",
        subSectorCode: "41520",
      },
      {
        subSectorName: "Programming and broadcasting activities",
        subSectorCode: "41530",
      },
      {
        subSectorName: "Telecommunications",
        subSectorCode: "41540",
      },
      {
        subSectorName:
          "Computer programming, consultancy and related activities",
        subSectorCode: "41550",
      },
      {
        subSectorName: "Information service activities",
        subSectorCode: "41560",
      },
    ],
  },
  {
    sector: "Professional, Scientific and Technical Activities",
    sectorCode: "41600",
    subSectors: [
      {
        subSectorName: "Legal and accounting activities",
        subSectorCode: "41610",
      },
      {
        subSectorName:
          "Activities of head offices; management consultancy activities",
        subSectorCode: "41620",
      },
      {
        subSectorName:
          "Architectural and engineering activities; technical testing and analysis",
        subSectorCode: "41630",
      },
      {
        subSectorName: "Scientific research and development",
        subSectorCode: "41640",
      },
      {
        subSectorName: "Advertising and market research",
        subSectorCode: "41650",
      },
      {
        subSectorName:
          "Other professional, scientific and technical activities",
        subSectorCode: "41660",
      },
      {
        subSectorName: "Veterinary activities",
        subSectorCode: "41670",
      },
    ],
  },
  {
    sector: "Administrative and Support Service Activities",
    sectorCode: "41700",
    subSectors: [
      {
        subSectorName: "Rental and leasing activities",
        subSectorCode: "41710",
      },
      {
        subSectorName: "Employment activities",
        subSectorCode: "41720",
      },
      {
        subSectorName:
          "Travel agency, tour operator, reservation service and related activities",
        subSectorCode: "41730",
      },
      {
        subSectorName: "Security and investigation activities",
        subSectorCode: "41740",
      },
      {
        subSectorName: "Services to buildings and landscape activities",
        subSectorCode: "41750",
      },
      {
        subSectorName:
          "Office administrative, office support and other business support activities",
        subSectorCode: "41760",
      },
    ],
  },
  {
    sector: "Education",
    sectorCode: "41800",
    subSectors: [
      {
        subSectorName: "Pre Primary and primary education",
        subSectorCode: "41810",
      },
      {
        subSectorName: "Post primary education",
        subSectorCode: "41820",
      },
      {
        subSectorName: "Tertiary education",
        subSectorCode: "41830",
      },
      {
        subSectorName: "Other education",
        subSectorCode: "41840",
      },
      {
        subSectorName: "Educational support services",
        subSectorCode: "41850",
      },
    ],
  },
  {
    sector: "Human Health and Social Work Activities",
    sectorCode: "41900",
    subSectors: [
      {
        subSectorName: "Human health activities",
        subSectorCode: "41910",
      },
      {
        subSectorName: "Residential care activities",
        subSectorCode: "41920",
      },
      {
        subSectorName: "Social work activities without accommodation",
        subSectorCode: "41930",
      },
    ],
  },
  {
    sector: "Arts, Entertainment and Recreation",
    sectorCode: "42000",
    subSectors: [
      {
        subSectorName: "Creative, arts and entertainment activities",
        subSectorCode: "42010",
      },
      {
        subSectorName:
          "Libraries, archives, museums and other cultural activities",
        subSectorCode: "42020",
      },
      {
        subSectorName: "Gambling and betting activities",
        subSectorCode: "42030",
      },
      {
        subSectorName:
          "Sports activities and amusement and recreation activities",
        subSectorCode: "42040",
      },
    ],
  },
  {
    sector: "Activities Of Extraterritorial Organizations and Bodies",
    sectorCode: "42100",
    subSectors: [
      {
        subSectorName:
          "Activities of Extraterritorial Organizations and Bodies",
        subSectorCode: "42110",
      },
    ],
  },
  {
    sector: "Power and Energy",
    sectorCode: "42200",
    subSectors: [
      {
        subSectorName: "Independent Power Projects (IPP)",
        subSectorCode: "42210",
      },
      {
        subSectorName: "Power generation/power plants",
        subSectorCode: "42220",
      },
      {
        subSectorName: "Power transmission",
        subSectorCode: "42230",
      },
      {
        subSectorName: "Power distribution",
        subSectorCode: "42240",
      },
    ],
  },
  {
    sector: "Capital Market",
    sectorCode: "42300",
    subSectors: [
      {
        subSectorName: "Margin lending",
        subSectorCode: "42310",
      },
      {
        subSectorName: "Proprietary trading",
        subSectorCode: "42320",
      },
      {
        subSectorName: "Share loans",
        subSectorCode: "42330",
      },
      {
        subSectorName: "Share underwriting",
        subSectorCode: "42340",
      },
      {
        subSectorName: "Bonds/ debt obligations",
        subSectorCode: "42350",
      },
    ],
  },
  {
    sector: "Oil and Gas",
    sectorCode: "42400",
    subSectors: [
      {
        subSectorName: "Oil - upstream",
        subSectorCode: "42420",
      },
      {
        subSectorName: "Natural gas",
        subSectorCode: "42430",
      },
      {
        subSectorName: "Crude oil refining",
        subSectorCode: "42440",
      },
      {
        subSectorName: "Oil & Gas Services",
        subSectorCode: "42450",
      },
    ],
  },
];

export const vehicleOptions = [
  {
    name: "Delivery Truck",
    label: "Delivery Truck",
    value: 0,
  },
  {
    name: "Sided Body",
    label: "Sided Body",
    value: 1,
  },
  {
    name: "Flat Bed Truck",
    label: "Flat Bed Truck",
    value: 2,
  },
  {
    name: "Tanker Truck",
    label: "Tanker Truck",
    value: 3,
  },
  {
    name: "Dump Truck",
    label: "Dump Truck",
    value: 4,
  },
  {
    name: "Others",
    label: "Others",
    value: 5,
  },
];
export const truckSizeOptions = [
  {
    name: "5 Tonnes",
    label: "5 Tonnes",
    value: 5,
  },
  {
    name: "10 Tonnes",
    label: "10 Tonnes",
    value: 10,
  },
  {
    name: "15 Tonnes",
    label: "15 Tonnes",
    value: 15,
  },
  {
    name: "20 Tonnes",
    label: "20 Tonnes",
    value: 20,
  },
  {
    name: "30 Tonnes",
    label: "30 Tonnes",
    value: 30,
  },
];
export const shippingMethodOptions = [
  {
    name: "Air Freight",
    label: "Air Freight",
    value: 0,
  },
];

export const industryCategoryOption = [
  {
    name: "Chemicals",
    label: "Chemicals",
    value: 0,
  },
];

export const productCategoryOption = [
  {
    name: "Agricultural Products",
    label: "Agricultural Products",
    value: 0,
  },
  {
    name: "Building Materials",
    label: "Building Materials",
    value: 1,
  },
  {
    name: "Clothing & Textile",
    label: "Clothing & Textile",
    value: 2,
  },
  {
    name: "Computers and Electronics",
    label: "Computers and Electronics",
    value: 3,
  },
  {
    name: "Furniture",
    label: "Furniture",
    value: 4,
  },
  {
    name: "Hazardous Materials",
    label: "Hazardous Materials",
    value: 5,
  },
  {
    name: "Heavy Machinery",
    label: "Heavy Machinery",
    value: 6,
  },
];

export const productClassOptions = [
  {
    name: "Fragile",
    label: "Fragile goods",
    value: 0,
  },
  {
    name: "Dangerous Goods",
    label: "Dangerous Goods (Hazardous Materials)",
    value: 1,
  },
  {
    name: "Cold Chain Goods",
    label: "Cold Chain Goods (Refrigerated/Frozen)",
    value: 2,
  },
  {
    name: "High Value Goods",
    label: "High Value Goods",
    value: 3,
  },
  {
    name: "Flammable Goods",
    label: "Flammable Goods",
    value: 4,
  },
  {
    name: "Perishable Goods",
    label: "Perishable Goods",
    value: 5,
  },
  {
    name: "Temperature Controlled Goods",
    label: "Temperature Controlled Goods",
    value: 6,
  },
];
export const ProductOptions = [
  {
    label: "All products",
    value: 0,
  },
  {
    label: "Selected Vendors",
    value: 1,
  },
  {
    label: "Selected Markets",
    value: 2,
  },
];

export const DiscountTypes = [
  {
    label: "Percentage",
    value: 0,
  },
  {
    label: "Amount",
    value: 1,
  },
];

export const DiscountOptions = [
  {
    label: "All users",
    value: 0,
  },
  {
    label: "First trade",
    value: 1,
  },
  {
    label: "Second trade",
    value: 2,
  },
  {
    label: "Selected users",
    value: 3,
  },
];

export const CampaignStatus = [
  {
    label: "Active",
    value: 0,
  },
  {
    label: "Inactive",
    value: 1,
  },
];

export const checkboxprops = {
  label: {
    type: String,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "checkbox",
  },
  activeClass: {
    type: String,
    default:
      " ring-black-500  bg-slate-900 darks:bg-slate-700 darks:ring-slate-700 ",
  },
  labelClass: {
    type: String,
  },
  value: {
    type: null,
  },
  modelValue: {
    type: null,
  },
};

export const iconsMap = [
  {
    text: "box",
    value: "box",
  },
  {
    text: "car",
    value: "car",
  },
  {
    text: "tape",
    value: "tape",
  },
  {
    text: "paint-roller",
    value: "paint-roller",
  },
  {
    text: "print",
    value: "print",
  },
  {
    text: "industry",
    value: "industry",
  },
  {
    text: "bag-shopping",
    value: "bag-shopping",
  },
  {
    text: "truck",
    value: "truck",
  },
  {
    text: "gas-pump",
    value: "gas-pump",
  },
  {
    text: "syringe",
    value: "syringe",
  },
  {
    text: "light-bulb",
    value: "light-bulb",
  },
  {
    text: "plug",
    value: "plug",
  },
  {
    text: "tractor",
    value: "tractor",
  },
  {
    text: "wheat-awn",
    value: "wheat-awn",
  },
  {
    text: "glass-water-droplet",
    value: "glass-water-droplet",
  },
  {
    text: "utensils",
    value: "utensils",
  },
  {
    text: "screw-driver-wrench",
    value: "screwdriver-wrench",
  },
  {
    text: "personal care",
    value: "pump-medical",
  },
  {
    text: "flask",
    value: "flask",
  },
  {
    text: "flask-vial",
    value: "flask-vial",
  },
  {
    text: "recycle",
    value: "recycle",
  },
  {
    text: "oil-can",
    value: "oil-can",
  },
  {
    text: "spray-can-sparkles",
    value: "spray-can-sparkles",
  },
  {
    text: "soap",
    value: "soap",
  },
  {
    text: "bottle-droplet",
    value: "bottle-droplet",
  },
  {
    text: "bottle-water",
    value: "bottle-water",
  },
  {
    text: "layer-group",
    value: "layer-group",
  },
  {
    text: "hand-holding-medical",
    value: "hand-holding-medical",
  },
  {
    text: "hand-holding-droplet",
    value: "hand-holding-droplet",
  },
];

export const options = [
  {
    label: "In cart",
    value: 0,
  },
  {
    label: "In progress",
    value: 0,
  },
  {
    label: "Payment confirmed",
    value: 1,
  },

  {
    label: "Order cancelled",
    value: 3,
  },
  {
    label: "Order completed",
    value: 2,
  },
];

export const subOptions = [
  {
    label: "In cart",
    value: "In cart",
  },
  {
    label: "In progress",
    value: "In progress",
  },
  {
    label: "Payment confirmed",
    value: "Payment confirmed",
  },

  {
    label: "Order cancelled",
    value: "Order cancelled",
  },
  {
    label: "Order completed",
    value: "Order completed",
  },
];
export const countryCodes = {
  "+1": "United States (+1)",
  "+44": "United Kingdom (+44)",
  "+91": "India (+91)",
  "+61": "Australia (+61)",

  // African Countries
  "+20": "Egypt (+20)",
  "+213": "Algeria (+213)",
  "+225": "Ivory Coast (+225)",
  "+233": "Ghana (+233)",
  "+234": "Nigeria (+234)",
  "+254": "Kenya (+254)",
  "+256": "Uganda (+256)",
  "+27": "South Africa (+27)",
  "+233": "Ghana (+233)",
  "+256": "Uganda (+256)",
  "+251": "Ethiopia (+251)",
  "+254": "Kenya (+254)",
  "+255": "Tanzania (+255)",
  "+257": "Burundi (+257)",
  "+251": "Ethiopia (+251)",
  "+212": "Morocco (+212)",
  "+213": "Algeria (+213)",
  "+260": "Zambia (+260)",
  "+263": "Zimbabwe (+263)",
  "+254": "Kenya (+254)",
  "+251": "Ethiopia (+251)",
  "+243": "Democratic Republic of the Congo (+243)",
  "+233": "Ghana (+233)",
  "+243": "Congo (+243)",
  "+220": "Gambia (+220)",
  "+234": "Nigeria (+234)",
  "+253": "Djibouti (+253)",
  "+251": "Ethiopia (+251)",
  "+227": "Niger (+227)",
  "+228": "Togo (+228)",
  "+241": "Gabon (+241)",
  "+245": "Guinea-Bissau (+245)",
  "+269": "Comoros (+269)",
  "+220": "Gambia (+220)",
  "+253": "Djibouti (+253)",

  // European Countries
  "+33": "France (+33)",
  "+34": "Spain (+34)",
  "+49": "Germany (+49)",
  "+39": "Italy (+39)",
  "+34": "Spain (+34)",
  "+44": "United Kingdom (+44)",
  "+32": "Belgium (+32)",
  "+31": "Netherlands (+31)",
  "+41": "Switzerland (+41)",
  "+43": "Austria (+43)",
  "+46": "Sweden (+46)",
  "+48": "Poland (+48)",
  "+36": "Hungary (+36)",
  "+353": "Ireland (+353)",
  "+358": "Finland (+358)",
  "+420": "Czech Republic (+420)",
  "+421": "Slovakia (+421)",
  "+378": "San Marino (+378)",
  "+356": "Malta (+356)",
  "+359": "Bulgaria (+359)",
  "+372": "Estonia (+372)",
  "+370": "Lithuania (+370)",
  "+371": "Latvia (+371)",
  "+380": "Ukraine (+380)",
  "+381": "Serbia (+381)",
  "+382": "Montenegro (+382)",
  "+386": "Slovenia (+386)",
  "+387": "Bosnia and Herzegovina (+387)",
  "+389": "North Macedonia (+389)",
  "+420": "Czech Republic (+420)",
  "+421": "Slovakia (+421)",
  "+372": "Estonia (+372)",

  // Asian Countries
  "+86": "China (+86)",
  "+81": "Japan (+81)",
  "+82": "South Korea (+82)",
  "+60": "Malaysia (+60)",
  "+63": "Philippines (+63)",
  "+66": "Thailand (+66)",
  "+65": "Singapore (+65)",
  "+88": "Bangladesh (+88)",
  "+974": "Qatar (+974)",
  "+971": "United Arab Emirates (+971)",
  "+92": "Pakistan (+92)",
  "+975": "Bhutan (+975)",
  "+977": "Nepal (+977)",
  "+977": "Nepal (+977)",
  "+62": "Indonesia (+62)",
  "+94": "Sri Lanka (+94)",
  "+996": "Kyrgyzstan (+996)",
  "+995": "Georgia (+995)",
  "+974": "Qatar (+974)",
  "+63": "Philippines (+63)",
  "+972": "Israel (+972)",
  "+971": "United Arab Emirates (+971)",
  "+996": "Kyrgyzstan (+996)",
  "+60": "Malaysia (+60)",
  "+62": "Indonesia (+62)",
  "+63": "Philippines (+63)",
  "+81": "Japan (+81)",
  "+852": "Hong Kong (+852)",
  "+886": "Taiwan (+886)",
};
export const metricsDataArray = [
  // {
  //   label: "Product view per vendor",
  //   key: "productviewspervendor",
  // },
  // {
  //   label: "Product view per product",
  //   key: "productviewsperproduct",
  // },
  {
    label: "General product view",
    key: "productviews",
  },
  // {
  //   label: "Add to cart per vendor",
  //   key: "cartviewspervendor",
  // },
  // {
  //   label: "Add to cart per product",
  //   key: "cartviewsperproduct",
  // },
  {
    label: "General add to cart",
    key: "cartviews",
  },
  {
    label: "GMV (add to cart value)",
    key: "cartvalue",
  },
  {
    label: "SMV (order created)",
    key: "cartvalueordercreated",
  },
  {
    label: "NMV (payment received)",
    key: "cartvaluepaymentreceived",
  },
  {
    label: "Marketplace Revenue",
    key: "revenue",
  },
  {
    label: "New Customer",
    key: "newcustomer",
  },
  {
    label: "New Products",
    key: "productcount",
  },
  {
    label: "Product Requests",
    key: "productrequestcount",
  },
  {
    label: "Finance Requests",
    key: "financerequestcount",
  },
  {
    label: "Shipping Quotes",
    key: "shippingquote",
  },
];

export const summaryDataArray = [
  {
    label: "Net Total Transaction Value",
    key: "net-total-transaction-value",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Total Transaction Value",
    key: "total-transaction-value",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Net Merchandise Value (NGN)",
    key: "cartvalue-views?",
    params: { OrderItemStatus: 2 },
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Net Transaction Value (NGN)",
    key: "net-transaction-value",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Revenue from MattaCredit (NGN)",
    key: "revenue-matta-credit",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Revenue from Marketplace (NGN)",
    key: "marketvalue",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Sell-Through-Rate",
    key: "sell-through-rate",
    hasMiniChart: true,
    isPercentage: true,
  },
];

export const marketPlaceDataArray = [
  {
    label: "Gross Merchandise Value (NGN)",
    key: "cartvalue-views",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Net Merchandise Value (NGN)",
    key: "cartvalue-views",
    params: {
      OrderItemStatus: "2",
    },
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Sales Order Merchandise Value (NGN)",
    key: "cartvalue-views",
    params: {
      OrderItemStatus: "1",
    },
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Cost of Goods Sold (NGN)",
    key: "marketplace-goods-sold",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Cost of Logistics (NGN)",
    key: "marketplace-shippingcost",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Average Order Value (NGN)",
    key: "average-order-value",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Revenue from Marketplace (NGN)",
    key: "marketvalue",
    hasMiniChart: true,
    isAmount: true,
  },
];

export const mattaCreditDataArray = [
  {
    label: "Gross Transaction Value",
    key: "gross-transaction-value",
    hasMiniChart: true,
    isAmount: true,
  },
  // {
  //   label: "Cost of Financing",
  //   key: "cost-of-financing",
  //   hasMiniChart: true,
  // },
  {
    label: "Revenue from MattaCredit (NGN)",
    key: "revenue-matta-credit",
    hasMiniChart: true,
    isAmount: true,
  },
  {
    label: "Net Transaction Value (NGN) ",
    key: "net-transaction-value",
    hasMiniChart: false,
    isAmount: true,
  },
  {
    label: "Total Credit Applications",
    key: "financerequest-count",
  },
  {
    label: "Total Credit Approvals (#)",
    key: "credit-approved-count",
  },
  {
    label: "Credit Approval Rate (%)",
    key: "credit-approved-rate",
    isPercentage: true,
  },
  {
    label: "Total Credit Wallet Requests (#)",
    key: "total-credit-request",
    hasMiniChart: true,
  },
  {
    label: "Active Credit Wallet (#)",
    key: "active-credit-wallets",
    hasMiniChart: true,
  },
  {
    label: "Total Overdue Loans (#)",
    key: "total-overdue-loans",
  },
  {
    label: "Overdue Loans Value (NGN)",
    key: "overdue-loan-value",
    isAmount: true,
  },
  {
    label: "Average Default Days (Days)",
    key: "average-default-days",
    isCount: true,
  },
  {
    label: "Default Rate",
    key: "default-rate",
    isPercentage: true,
  },
];

export const merchantDataArray = [
  // {
  //   label: "Total Merchant Signup",
  //   key: "merchant-total-count",
  //   isCount: true,
  // },
  {
    label: "Total Merchants (#)",
    key: "merchant-total-count",
    isCount: true,
  },
  {
    label: "Total Active Merchants (#)",
    key: "merchant-active-count",
    isCount: true,
  },
  {
    label: "Conversion Rate (CVR %)",
    key: "merchant-conversion-rate",
    isPercentage: true,
  },
  {
    label: "Average Revenue per Merchant (NGN)",
    key: "merchant-average-revenue",
    isAmount: true,
  },
  {
    label: "Average # Orders per Merchant (#)",
    key: "merchant-average-order",
    isCount: true,
  },
  {
    label: "Revenue generated by Top 5 Merchants",
    key: "total-merchant-sales",
    handleData: (res) => {
      const m = Object.values(res.data.data).reduce((a, b) => a + b, 0);
      return { total: m, percentChange: null };
    },
    isAmount: true,
  },

  {
    label: "Merchant to Vendor Ratio",
    key: "merchant-vendor-ratio",
    isRatio: true,
    lower: "merchantCount",
    upper: "vendorCount",
  },
];

export const vendorDataArray = [
  // {
  //   label: "Total Vendor Sign ups (#)",
  //   key: "total-vendor-count",
  //   isCount: true,
  // },
  {
    label: "Total Active Vendors (#)",
    key: "total-active-vendors",
    isCount: true,
  },
  {
    label: "Total Vendor (#)",
    key: "total-vendor-count",
    isCount: true,
  },
  {
    label: "Vendor without product",
    key: "vendor-no-products",
    isCount: true,
  },
  {
    label: "Average Transaction Value per Vendor (NGN)",
    key: "average-transactions-per-vendor",
    isAmount: true,
  },
  {
    label: "Top Vendors by Sales (NGN)",
    key: "total-vendor-sales",
    isTable: true,
    isAmount: true,
  },
];

export const conversionDataArray = [
  {
    label: "Vendor Activation Rate (%)",
    key: "vendor-activation-rate",
    isPercentage: true,
  },
  {
    label: "Repeat Buyer Rate (%)",
    key: "repeat-buyer-rate",
    isPercentage: true,
  },
  {
    label: "Vendor-Sale Conversion Rate (%)",
    key: "vendor-sale-conversion-rate",
    isPercentage: true,
  },
  {
    label: "Number of request a call",
    key: "call-request-total",
    isCount: true,
  },
  {
    label: "Request a call conversion rate",
    key: "call-request-conversion-rate",
    isPercentage: true,
  },
];

export const activityAndUsageDataArray = [
  {
    label: "Product Views per Buyer (#)",
    key: "product-view-per-buyer",
    isCount: true,
  },
  {
    label: "Anonymous Views per Buyer (#)",
    key: "anonoymous-view-per-buyer",
    isCount: true,
  },
  {
    label: "Daily Active Buyers (DAB) (#)",
    key: "daily-active-buyers",
    isCount: true,
  },
  {
    label: "Daily Active Vendors (DAV) (#)",
    key: "daily-active-vendors",
    isCount: true,
  },
  {
    label: "Search Query Volume(#)",
    key: "search-query-volume",
    isCount: true,
  },
];

export const productDataArray = [
  {
    label: "Total Product (#)",
    key: "product-views",
  },
  // {
  //   label: "Total New Product (#)",
  //   key: "product-views",
  // },
  {
    label: "Sell-Through-Rate",
    key: "sell-through-rate",
    isPercentage: true,
  },
  // {
  //   label: "Top Verticals by Revenue",
  //   key: "verticals-by-revenue",
  // },
  // {
  //   label: "Top Products by Revenue",
  //   key: "products-by-revenue",
  // },
  // {
  //   label: "Product view per Vendor",
  //   key: "productview-vendor",
  // },
  // {
  //   label: "Product views per Product",
  //   key: "productview-product",
  // },
];
export const AppOptions = [
  {
    label: "Matta",
    key: 0,
  },
  {
    label: "Flux",
    key: 1,
  },
  {
    label: "Oxide",
    key: 3,
  },
  {
    label: "Orbital",
    key: 4,
  },
];
export const LoanDataArray = [
  {
    label: "How much do you require?",
    key: "amountRequired",
  },
  {
    label: "Tenor",
    key: "tenor",
  },
  {
    label: "Where did you hear about us? ",
    key: "whereDidYouHearAboutUs",
  },
  {
    label: "Company name",
    key: "companyName",
  },
  {
    label: "Business type",
    key: "category",
  },
  {
    label: "Date of incorporation",
    key: "dateofIncorporation",
  },
  {
    label: "Address",
    key: "address",
  },
  {
    label: "Sector",
    key: "sector",
  },
  {
    label: "Brief description of product",
    key: "description",
  },
  {
    label: "Have you done business with this buyer before",
    key: "haveyoudonebusiness",
  },
  {
    label:
      "Have you previously exported to the order’s country of destination?",
    key: "haveyouexportedtotheothercourty",
  },
];

export const nigeriaTypes = [0, 1, 2, 3, 4];
export const nonNigeriaTypes = [0, 4];

export const documentsOptions = {
  1: {
    title: "Memorandum and Articles of Association",
    short: "Mermat",
    isNigeria: true,
    isNonNigeria: false,
  },
  0: {
    title: "Certificate of incorporation",
    short: "Certificate of incorporation",
    isNigeria: true,
    isNonNigeria: true,
  },
  2: {
    title: "CAC  Status report",
    short: "CAC  Status report",
    isNigeria: true,
    isNonNigeria: false,
  },
  3: {
    title: "Utility Bill",
    short: "Utility Bill",
    isNigeria: true,
    isNonNigeria: false,
  },
  4: {
    title: "Company profile",
    short: "Company profile",
    value: 4,
    isNigeria: true,
    isNonNigeria: true,
  },
};

export const KybDocumentDefault = [
  {
    urls: [
      {
        url: "",
      },
    ],
    url: "",
    documentType: 0,
  },
  {
    urls: [
      {
        url: "",
      },
    ],
    url: "",
    documentType: 1,
  },
  {
    urls: [
      {
        url: "",
      },
    ],
    url: "",
    documentType: 2,
  },
  {
    urls: [
      {
        url: "",
      },
    ],
    url: "",
    documentType: 3,
  },
  {
    urls: [
      {
        url: "",
      },
    ],
    url: "",
    documentType: 4,
  },
];

export const tabs = [
  {
    title: "No Of Requests",
    count: "0",
    key: "numberofRequests",
    className: "bg-[#CCFBEF]/40 text-[#15B79E]",
    icon: "ph:users",
  },
  {
    title: "Disbursed Loans",
    count: "0",
    key: "disbursedLoan",
    className: "bg-[#D1E9FF]/40 text-[#53B1FD]",
    icon: "bi:shop",
  },
  {
    title: "Overdue Loans",
    count: "0",
    key: "overDueLoan",
    className: "bg-[#FEF0C7]/40 text-[#FDB022]",
    icon: "lucide:shopping-bag",
  },
];

export const StatusEnums = {
  // 0: "None",
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
  4: "Assigned",
  5: "In Transit",
  6: "Fully Paid",
  7: "Partially Paid",
  8: "Delivered",
  9: "Cancelled",
};
export const UpdateStatusEnums = {
  4: "Assigned",
  5: "In Transit",
  8: "Delivered",
};
export const statusDictionary = {
  pending: 0,
  "in-progress": 2,
  accepted: 2,
  rejected: 3,
  "in-transit": 4,
  delivered: 5,
  cancelled: 6,
};

export const adminStatusDictionary = {
  pending: 1,
  "in-progress": 1,
  accepted: 2,
  rejected: 3,
  assigned: 4,
  "in-transit": 5,
  delivered: 8,
  cancelled: 9,
};
