import axios from "axios";
import { getItem } from "@/utils/localStorageControl.js";
import { useToast } from "vue-toast-notification";
import store from "../store";
import { AppCodes } from "@/utils/constants";

// eslint-disable-next-line no-unused-vars
const appId = getItem("selectedApp") ?? AppCodes.matta;

export const API_URL = process.env.VUE_APP_URL;
export const OXIDE_API_URL = process.env.VUE_APP_OXIDE_API_URL;
export const FLUX_API_URL = process.env.VUE_APP_FLUX_API_URL;
export const ORBITAL_API_URL = process.env.VUE_APP_ORBITAL_API_URL;
export const SSO_API_URL = process.env.VUE_APP_SSO_API_URL;

export const WALLET_API_URL = process.env.VUE_APP_WALLET_API_URL;
export const GATEWAY_API_URL = process.env.VUE_APP_GATEWAY_APP_URL;
export const CURRENCY_API_URL = process.env.VUE_APP_CURRENCY_API_URL;

const ApiUrl = {
  [AppCodes.matta]: API_URL,
  [AppCodes.oxide]: OXIDE_API_URL,
  [AppCodes.orbital]: ORBITAL_API_URL,
  [AppCodes.flux]: FLUX_API_URL,
};

// eslint-disable-next-line no-unused-vars
const toast = useToast();

// To ensure refresh logic runs once
let isRefreshing = false;
let subscribers = [];

// Function to notify all queued subscribers
function onRefreshed(newAccessToken) {
  subscribers.forEach((callback) => callback(newAccessToken));
  subscribers = [];
}

// Add subscribers for token refresh
function addSubscriber(callback) {
  subscribers.push(callback);
}

// Function to create an axios instance with a specific base URL
// Add retry count tracking
let retryCount = 0;
const MAX_RETRIES = 2;

function createAxiosInstance(baseUrl = API_URL) {
  const axiosInstance = axios.create({
    baseURL: baseUrl ?? ApiUrl[appId],
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 403) {
        if (!isRefreshing && retryCount < MAX_RETRIES) {
          isRefreshing = true;
          retryCount++;

          try {
            const refreshResponse = await axios.post(
              `${SSO_API_URL}/v1/Account/refreshtoken`,
              {
                token: store.getters.refreshToken,
                ipAddress: "string",
              }
            );

            const newAccessToken = refreshResponse.data.jwToken;
            const newRefreshToken = refreshResponse.data.refreshToken;

            store.commit("setAccessToken", newAccessToken);
            store.commit("setRefreshToken", newRefreshToken);
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;

            onRefreshed(newAccessToken);
            isRefreshing = false;

            return axiosInstance.request(error.config);
          } catch (refreshError) {
            isRefreshing = false;
            toast.info("Your session has expired", { position: "bottom" });
            return Promise.reject(refreshError);
          }
        } else if (retryCount >= MAX_RETRIES) {
          // toast.info("Maximum retry attempts reached", { position: "bottom" });
          return Promise.reject(error);
        } else {
          return new Promise((resolve) => {
            addSubscriber((newAccessToken) => {
              error.config.headers[
                "Authorization"
              ] = `Bearer ${newAccessToken}`;
              resolve(axiosInstance.request(error.config));
            });
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

// API Helper Functions

// GET Request
export async function get(url, config = {}, baseUrl = null) {
  const axiosInstance = createAxiosInstance(baseUrl);
  return await axiosInstance.get(url, config);
}
export async function walletGet(url, config = {}) {
  const axiosInstance = createAxiosInstance(WALLET_API_URL);
  return await axiosInstance.get(url, config);
}

// POST Request
export async function post(url, data, config = {}, baseUrl = null) {
  const axiosInstance = createAxiosInstance(baseUrl);
  return await axiosInstance.post(url, data, config);
}
export async function gatewayPost(url, data, config = {}) {
  const axiosInstance = createAxiosInstance(GATEWAY_API_URL);
  return await axiosInstance.post(url, data, config);
}

// PUT Request
export async function put(url, data, config = {}, baseUrl = null) {
  const axiosInstance = createAxiosInstance(baseUrl);
  return await axiosInstance.put(url, data, config);
}

// DELETE Request
export async function del(url, config = {}, baseUrl = null) {
  const axiosInstance = createAxiosInstance(baseUrl);
  return await axiosInstance.delete(url, config);
}
