const version = "v1/";
const version_2 = "v2/";
const urls = {
  //Auth urls
  LOGIN_USER: version + "account/login",
  LOGIN_OUT: "v1/account/logout",
  LOGIN_USER_2FA: "v1/account/login2fa",
  REGISTER: "/" + version + "register",
  RESEND_VERIFICATION: "/" + version + "register/resendverification",
  REGISTER_INVITED_USER: "/" + version + "account/registerbusinessuser",
  FORGOT_PASSWORD: version + "account/forgot-password",
  RESET_PASSWORD: version + "account/reset-password",
  CONFIRM_EMAIL: version + "account/confirm-email",
  SET_ACCOUNT_TYPE: version + "onboarding/setaccounttype",
  SOCIAL_REGISTER: version + "register/social",
  SOCIAL_LOGIN: version + "account/sociallogin",
  GET_ACCOUNT_ROLES: version + "account/roles",
  RESEND_2FA_OTP: "v1/account/resend-2fa-code",

  // SIGN_UP_WITH_MATTA: "v1/onboarding/register/invited-user",
  SIGN_UP_WITH_MATTA: "/v1/onboarding/sign-up-with-matta-account",
  //Onboarding
  COMPANY_UPDATE_PERSONAL_INFO:
    "/" + version + "Onboarding/company/update-PersonalInfo",
  COMPANY_UPDATE_ADDITIONAL_INFORMATION:
    "/" + version + "Onboarding/company/additonal-information",
  COMPANY_INVITE_USERS: "admin/" + version + "Onboarding/company/invite-users",
  GET_ONBOARDING_INFO: version + "onboarding",
  SETTINGS: "/" + version + "settings",
  SETTINGS_SETTIMEZONE: "admin/" + version + "settings/settimezone",
  SETONBOARDING_COMPLETE: version + "onboarding/setonboardingpageascomplete",

  //Product
  ADD_PRODUCT: "admin/" + version + "product/add-product",
  UPDATE_PRODUCT: "admin/" + version + "product/update-product",
  UPDATE_PRODUCT_PROPERTIES: "admin/" + version + "product/update-properties",
  UPDATE_PRODUCT_DOCUMENTS:
    "admin/" + version + "product/update-productdocuments",
  UPDATE_PRODUCT_ADDITIONAL:
    "admin/" + version + "product/update-additionalinfo",
  GET_PRODUCTS: "/" + version + "marketplace/get-products",
  GET_PRODUCTS_V2: "/" + version_2 + "marketplace/products",
  GET_PRODUCT: "/" + version + "Product/get-product",
  SUPPLIER_GET_PRODUCTS: "admin/" + version + "product/get-products",
  SUPPLIER_GET_PRODUCT: "admin/" + version + "product/get-product",
  GET_PRODUCT_STATUS_COUNT: "admin/" + version + "product/get-productscount",
  DELETE_PRODUCT: "admin/" + version + "product/deleteproduct",
  BULK_EDIT: "admin/" + version + "Product/bulk-price-change",

  //Markets
  GET_MARKETS: "admin/" + version + "market/getall",
  GET_MARKET_MENU: "/" + version + "marketplace/getmarkets-menu",
  SEARCH_MARKET: version + "marketplace/searchall",
  GET_MARKETS_LEVEL: "admin/" + version + "market/getmarketlevels",

  //Producer
  GET_PRODUCERS: "/" + version + "marketplace/get-producers",
  ADD_PRODUCER: "admin/" + version + "producer/addproducer",
  EDIT_PRODUCER: "admin/" + version + "producer/editproducer",

  //suppliers
  GET_SUPPLIERS: "/" + version + "suppliercatalog/getall",
  GET_SUPPLIER_DETAIL: "/" + version + "suppliercatalog/supplierdetails",
  GET_SUPPLIER_DOCUMENTS: "/" + version + "suppliercatalog/supplierdocuments",

  //Tech
  GET_TECH: "admin/" + version + "technology/getall",
  GET_TECH_LEVEL: "admin/" + version + "technology/gettechnologylevels",
  GET_TECH_MARKET: "/" + version + "technologies/techmarket",
  GET_TECH_MENU: "/" + version + "technologies/tech-menu",

  //Manuacturers
  GET_MANUFACTURERS: "/" + version + "marketplace/get-featured-manufacturers",

  //Upload
  UPLOAD_FILE: "/" + version + "fileservice/uploadsinglephoto",
  UPLOAD_DOCUMENT: "/" + version + "fileservice/uploadsingledocument",

  //settings
  GET_PROFILE: version + "register/user-profile",
  UPDATE_BUYER_PROFILE: "admin/" + version + "customer/updateprofile",
  GET_BUYER_PROFILE: "admin/" + version + "customer/getprofile",
  GET_COMPANY_PROFILE: "admin/" + version + "settings/company/profile",
  ADMIN_GET_COMPANY_PROFILE: "admin/" + version + "businessprofile",

  ADMIN_STATUS_UPDATE: "admin/" + version + "customer/company/update-approval",
  UPDATE_PROFILE: version + "settings/updateprofile",
  CHANGE_PASSWORD: version + "settings/change-password",
  UPDATE_COMPANY_PROFILE:
    "admin/" + version + "customer/company/update-profile",
  UPDATE_DOCUMENTS: "admin/v1/customer/company/update-documents",
  UPDATE_DIRECTORS: "admin/v1/customer/company/update-directors",
  SET_TIMEZONE: version + "settings/settimezone",
  DELETE_ACCOUNT: version + "settings/delete-account",
  STOREFRONT_STAT: version + "storefront/stats",
  STOREFRONT_TRENDING_PRODUCT:
    "admin/" + version + "storefront/trendingproducts",

  //Users
  INVITE_USERS: "admin/" + version + "users/invite-users",
  RESEND_INVITE_USERS: "admin/" + version + "users/resend-invite",
  GET_INVITES: "admin/" + version + "users/get-invites",
  DELETE_INVITES: "admin/" + version + "users/delete-invite",
  DELETE_ADMIN: "admin/" + version + "users/delete-user",
  DELETE_USER: "admin/" + version + "usermanagement/delete-user",
  CHANGE_ROLE: "admin/" + version + "users/change-usersrole",
  GET_ROLES: "admin/" + version + "users/get-roles",
  GET_SUBAPPS: "admin/v1/subapplication/apps",

  //Static values
  GET_COUNTRIES: "/" + version + "staticvalues/countries",

  //cart
  CREATE_CART: "admin/" + version + "shoppingcart/create-cart",
  GET_CART: "admin/" + version + "shoppingcart/get-cart",
  UPDATE_CART: "admin/" + version + "shoppingcart/update-cartitem",
  REMOVE_CART: "admin/" + version + "shoppingcart/remove-cartitem",

  CONFIRM_PURCHASE: "admin/" + version + "shoppingcart/confirm-purchase",
  SHIPPONG_COST_BREAKDOWN: "admin/v1/ShoppingCart/shipping-cost-breakdown",

  //Shipping address
  ADD_SHIPPING_ADDRESS: "admin/" + version + "shippingaddress/add",
  EDIT_SHIPPING_ADDRESS: "admin/" + version + "shippingaddress/edit",
  GET_SHIPPING_ADDRESS: "admin/" + version + "shippingaddress/getall",
  DEFAULT_SHIPPING_ADDRESS: "admin/" + version + "shippingaddress/setasdefault",
  DELETE_SHIPPING: "admin/" + version + "shippingaddress/delete",

  //Procurement
  PROCUREMENT_ORDERS: "admin/" + version + "procurement/orders",
  PROCUREMENT_ORDER_DETAILS: "admin/" + version + "procurement/orderdetails",
  PROCUREMENT_REQUEST_COUNT: "admin/" + version + "procurement/requestcount",
  PROCUREMENT_MYREQUESTS: "admin/" + version + "procurement/myrequests",
  PROCUREMENT_MYREQUEST_DETAILS:
    "admin/" + version + "procurement/request-details",
  PROCUREMENT_PRODUCTS: version + "procurement/products",
  PROCUREMENT_SUPPLIERS: version + "procurement/suppliers",
  PROCUREMENT_SET_CANCELLED: version + "procurement/setascancelled",
  UPDATE_ORDER_ITEM_STATUS: "admin/" + version + "procurement/update-status",

  //Storefront
  STOREFRONT_ORDERS: "admin/" + version + "storefront/orders",
  STOREFRONT_ORDER_DETAILS: version + "storefront/orderdetails",

  //SAMPLE requests
  ADD_SAMPLE_REQUEST: "admin/" + version + "procurement/newsamplerequest",
  SAMPLE_REQUESTS: "admin/" + version + "samplerequest/myrequests",
  SAMPLE_REQUESTS_COUNT: "admin/" + version + "samplerequest/requestcount",
  SAMPLE_REQUESTS_PRODUCTS: "admin/" + version + "samplerequest/products",
  SAMPLE_REQUEST_DETAILS: "admin/" + version + "samplerequest/request-details",

  //document requests
  ADD_DOCUMENT_REQUEST: "admin/" + version + "buyerdocument/newsamplerequest",
  DOCUMENT_REQUESTS: "admin/" + version + "buyerdocument/myrequests",
  DOCUMENT_REQUEST_DETAILS:
    "admin/" + version + "buyerdocument/request-details",
  DOCUMENT_SET_CANCELLED: "admin/" + version + "procurement/setascancelled",

  SELLER_DOCUMENTS: "admin/" + version + "sellerdocument/myrequests",
  SELLER_DOCUMENT_DETAILS:
    "admin/" + version + "sellerdocument/request-details",
  SELLER_DOCUMENT_SET_CANCELLED:
    "admin/" + version + "procurement/setascancelled",
  BUYER_ORDER_TIMELINE: "admin/" + version + "procurement/ordertimeline",

  //notifcations
  GET_NOTIFICATION: version + "notification",
  // GET_NOTIFICATION_SETTINGS: version+"notification/getsettings",
  // UPDATE_NOTIFICATION_SETTINGS: version+"notification/updatesettings",
  MARK_NOTIFICATION: version + "notification/markasviewed",
  MARK_ALL_NOTIFICATION: version + "notification/markallasviewed",

  //favourites
  LIKE_PRODUCT: version + "likes/likeproduct",
  UNLIKE_PRODUCT: version + "likes/unlikeproduct",
  GET_LIKED_PRODUCT: version + "likes/likedproducts",

  LIKE_SUPPLIER: version + "likes/likesupplier",
  UNLIKE_SUPPLIER: version + "likes/unlikesupplier",
  GET_LIKED_SUPPLIERS: version + "likes/likedsuppliers",

  //Quotes
  NEW_QUOTE: "admin/" + version + "quotes/new",
  SELLER_QUOTES: "admin/" + version + "quotes/seller",
  SELLER_QUOTE_DETAIL: "admin/" + version + "quotes/seller/details",
  BUYER_QUOTES: "admin/" + version + "quotes/buyer",
  BUYER_QUOTE_DETAIL: "admin/" + version + "quotes/seller/details",

  //Charts
  STAT_TREND: "admin/" + version + "dashboad/stats",
  CHART_TREND: "admin/" + version + "dashboad/ordertrendchart",
  TOP_PRODUCTS: "admin/" + version + "dashboad/top-productchart",

  //Admin
  GET_ZOHO_CONTACTS: "admin/" + version + "customer/get-zohocontacts",

  //Suppliers create
  CUSTOMER_CREATE: "admin/" + version + "customer/create",

  //Audit logs
  GET_AUDIT_LOGS: "admin/" + version + "audit/get-all",

  // product request
  GET_ALL_PRODUCT_REQUEST: "admin/" + version + "productrequest/get-all",

  //market settings

  ADD_MARKET: "admin/" + version + "market/addmarket",
  EDIT_MARKET: "admin/" + version + "market/updatemarket",
  DELETE_MARKET: "admin/" + version + "market/delete",
  ADD_MARKET_CATEGORY: "admin/" + version + "market/addcategory",
  EDIT_MARKET_CATEGORY: "admin/" + version + "market/updatecategory",
  DELETE_MARKET_CATGORY: "admin/" + version + "deletecategory",
  ADD_MARKET_SUBCATEGORY: "admin/" + version + "market/addsubcategory",
  EDIT_MARKET_SUBCATEGORY: "admin/" + version + "market/updatesubcategory",
  DELETE_MARKET_SUBCATEGORY: "admin/" + version + "deletesubcategory",

  ADD_APPLICATION: "admin/" + version + "technology/add",
  EDIT_APPLICATION: "admin/" + version + "technology/edit",
  DELETE_APPLICATION: "admin/" + version + "technology/delete",
  ADD_APPLICATION_CATEGORY:
    "admin/" + version + "technology/addtechapplication",
  EDIT_APPLICATION_CATEGORY: "admin/" + version + "technology/editcategory",
  DELETE_APPLICATION_CATEGORY: "admin/" + version + "technology/deletecategory",
  ADD_APPLICATION_SUBCATEGORY:
    "admin/" + version + "technology/addsubapplication",
  EDIT_APPLICATION_SUBCATEGORY:
    "admin/" + version + "technology/editsubcategory",
  DELETE_APPLICATION_SUBCATEGORY:
    "admin/" + version + "technology/deltersubcategory",
  //publish
  PUBLISH_PRODUCT: "admin/" + version + "product/publish",

  //Finance
  GET_ALL_FINANCE: "admin/v1/financing/get-all",
  GET_FINANCE_STAT: "admin/v1/financing/stats",
  ADD_FINANCE: "admin/v1/financing/add",
  EDIT_FINANCE: "admin/v1/financing/edit",
  UPDATE_APPROVE_FINANCE: "admin/v1/financing/financing/update-approval",
  WITHDRAW_FINANCE: "admin/v1/financing/withdraw",
  GET_FINANCE: "admin/v1/financing/get",
  FINANCE_APPROVE_DISBURSAL:
    "admin/v1/financing/financing/disboursement-approval",

  CUSTOMIZE_VENDOR_INFO: "admin/v1/customize/vendor",
  GET_VENDOR_STORE: "admin/v1/storefront/get-store",
  UPDATE_VENDOR_STORE: "admin/v1/storefront/update-store",
  POST_VENDOR_STORE_NAME: "admin/v1/storefront/search-name",

  //Settlements
  AUTO_SETTLEMENT: "admin/v1/customer/company/autosettlement",
  AUTO_SETTLEMENT_VALUE: "admin/v1/customer/company/autosettlement-value",
  ADD_SETTLEMENT: "admin/v1/customer/company/add-settlement-account",
  VIEW_SETTLEMENT: "admin/v1/customer/company/view-settlement-account",
  UPDATE_SETTLEMENT: "admin/v1/customer/company/update-settlement-account",
  DELETE_SETTLEMENT: "admin/v1/customer/company/delete-settlement-account",
  GET_BANKS: "v1/bank/get-bank",
  VALIDATE_ACCOUNT: "v1/wallet/validate-account",
  VALIDATE_BVN: "v1/wallet/verify-bvn",

  //Payment requests
  GET_PAYMENT_REQUESTS: "admin/v1/paymentRequest/view-payment-request",
  APPROVE_PAYMENT_REQUEST: "admin/v1/paymentRequest/approve-payout",
  REJECT_PAYMENT_REQUEST: "admin/v1/paymentRequest/reject-payout",
  REJECT_REFUND_PAYMENT_REQUEST: "admin/v1/paymentRequest/reject-refund-payout",
  MAKE_PAYMENT_REQUEST: "admin/v1/paymentRequest/make-payment",
  AUTHORIZE_PAYMENT_REQUEST: "admin/v1/paymentRequest/authorize-transfer",

  GET_SHIPPING_DATA: "admin/v1/shippingorders/get-all",

  //wallet
  CREATE_WALLET: "v1/wallet/new",
  CHECK_BALANCE: "admin/v1/wallet/get-balance",
  GET_WALLET_DETAILS: "v1/wallet/customer-wallet-details",
  ADMIN_GET_WALLET_DETAILS: "/admin/v1/wallet/customer-wallet-details",
  WALLET_REVERSAL: "/admin/v1/Wallet/reverse",
  ADMIN_GET_WALLET_TRANSACTIONS: "/admin/v1/wallet/get-wallet-transactions",
  SET_PIN: "v1/wallet/setpin",
  CHANGE_PIN: "v1/wallet/change-pin",
  VALIDATE_OTP: "v1/wallet/validate-otp",
  SET_WARNING_LIMIT: "v1/wallet/setwarninglimit",
  SET_UPPER_LIMIT: "v1/wallet/setupperlimit",
  CONFIRM_FUNDING: "v1/wallet/confirm-wallet-funding",
  WITHDRAW_FUNDS: "v1/wallet/withdraw",
  ALL_WALLET_BALANCES: "/admin/v1/Wallet/get-wallet-balances",

  ADDRESS_SEARCH: "Location/address-search",
  PLACE_SUGGESTION: "Location/place-suggestion",

  ADD_PICKUP_ADDRESS: "/admin/v1/pickuplocation/add",
  EDIT_PICKUP_ADDRESS: "/admin/v1/pickuplocation/edit",
  GET_PICKUP_ADDRESS: "/admin/v1/pickuplocation/get-pickuplocations",
  DELETE_PICKUP: "/admin/v1/pickuplocation/delete",

  // campaign
  GET_ALL_CAMPAIGN: "/admin/v1/campaign/get-all",
  CAMPAIGN_ADD: "/admin/v1/campaign/add",
  CAMPAIGN: "/admin/v1/campaign",
  CAMPAIGN_EDIT: "/admin/v1/campaign/edit",
  CAMPAIGN_UPDATE_STATUS: "/admin/v1/campaign/update-status",
  CAMPAIGN_GENERATE_CODE: "/admin/v1/campaign/generateCode",
  GET_CAMPAIGN_TRANSACTIONS: "/admin/v1/campaign/transactions",

  APPLY_DISCOUNT: "/admin/v1/discount/apply-discount",

  //metrics
  GET_METRICS_PRODUCT_VIEWS: "/admin/v1/metrics/product-views",
  GET_METRICS_CART_VIEWS: "/admin/v1/metrics/cart-views",
  GET_METRICS_CARTVALUE_VIEWS: "/admin/v1/metrics/cartvalue-views",
  GET_METRICS_PRODUCT_COUNT: "/admin/v1/metrics/product-count",
  GET_METRICS_PRODUCTREQUEST_COUNT: "/admin/v1/metrics/productrequest-count",
  GET_METRICS_FINANCEREQUEST_COUNT: "/admin/v1/metrics/financerequest-count",
  GET_METRICS_SHIPPINGQUOTE_COUNT: "/admin/v1/metrics/shippingquote-count",
  GET_METRICS_NEW_CUSTOMER: "/admin/v1/metrics/customer-count",
  GET_METRICS_REVENUE: "/admin/v1/metrics/marketvalue",
  GET_METRICS: "/admin/v1/Metrics",

  // AGENTS

  GET_AGENTS: "/Agent/view-agents",
  GET_AGENT_CUSTOMERS: "/Agent/agent-customers",
  GET_AGENT_CUSTOMER_ORDERS: "/Agent/customer-products",
  ASSIGN_TO_AGENT: "admin/v1/Customer/AssignCustomerToAgent",
  // Credit
  REQUEST_CREDIT_UPDATE_STATUS: "admin/v1/CreditRequest/update-status",
  GET_ALL_CREDIT_REQUEST: "admin/v1/CreditRequest/get-all-requests",
  UPDATE_CREDIT_LIMIT: "admin/v1/CreditRequest/update-credit-limit",
  UPDATE_WALLET_STATUS: "admin/v1/CreditRequest/update-wallet-status",
  GET_CREDIT_DETAIL: "admin/v1/CreditRequest/wallet",
  GET_CREDIT_REQUEST_BY_ID: "admin/v1/CreditRequest/get",
  CREDIT_APPROVE_DISBURSAL: "admin/v1/CreditRequest/approval-status",

  VENDOR_ORDER_STATUS_CHANGE: "/v1/vendor/order/status-change",
  GET_DELIVERY_CODE: "/v1/vendor/order/delivery-code",
  VENDOR_APPROVE_ORDER: "/v1/vendor/order/approve",

  // Drivers
  GET_ALL_DRIVERS: "/v1/drivers/getall-drivers",
  ADD_DRIVER: "/admin/v1/drivers/add-driver",
  UPDATE_DRIVER: "/admin/v1/drivers/update-driver",
  REMOVE_DRIVER: "/admin/v1/drivers/remove-driver",
  GET_DRIVER: "/admin/v1/drivers/get",

  GET_VENDOR_ORDERS: "/v1/vendor/orders",

  ADMIN_GET_ALL_DRIVERS: "/admin/v1/drivers/getall-drivers",
  ADMIN_GET_DRIVER: "/admin/v1/drivers/get",
  ADMIN_APPROVE_DRIVER: "/admin/v1/Drivers/approve-driver",

  ADMIN_GET_MERCHANT_ORDER_REQUESTS: "/admin/v1/merchant/order-requests",
  ADMIN_GET_MERCHANT_CUSTOMERS: "/admin/v1/merchant/customers",
  ADMIN_GET_MERCHANT: "/admin/v1/merchant",
  ADMIN_GET_MERCHANT_TRANSACTIONS: "/admin/v1/merchant/transactions",

  ADMIN_GET_VENDOR_ORDER_REQUESTS: "/admin/v1/vendor/order-requests",
  ADMIN_ASSIGN_VENDOR: "/admin/v1/vendor/assign-order",
  ADMIN_GET_VENDOR: "/admin/v1/vendor",

  VENDOR_ASSIGN_DRIVER: "/admin/v1/vendor/order/assign",
  VENDOR_SEND_CODE: "/v1/vendor/order/email-delivery-code",
  ADMIN_GET_VEHICLE: "/admin/v1/vehicle",
  ADMIN_APPROVE_VEHICLE: "/admin/v1/vehicle/approve",
  WAYBILL: "admin/v1/Vendor/waybill",

  // Vehicles
  GET_ALL_VEHICLES: "/v1/Vendor/vehicles",
  ADD_VEHICLE: "/admin/v1/vehicle",
  REMOVE_VEHICLE: "/v1/Vendor/vehicle",

  // Shipping Requests
  GET_ALL_SHIPPING_REQUESTS: "/v1/shippingrequest/get-all",
  SHIPPNG_ESTIMATE: "v1/ShippingRequest/shipping-estimate",
  ADD_SHIPPING_REQUEST: "/v1/ShippingRequest/add-request",
  GET_SINGLE_REQUEST: "v1/ShippingRequest/get-request",
  CANCEL_SHIPPING: "v1/ShippingRequest/cancel-request",

  // Metrics
  SHIPPING_METRIC: "v1/Metrics/shipping-request-count",
  SHIPPING_METRIC_INPROGRESS: "v1/Metrics/shipping-request-inprogress",
  SHIPPING_METRIC_COMPLETE: "v1/Metrics/shipping-request-complete",
  GET_VENDORS_FOR_SHIPPING: "/v1/ShippingRequest/vendor-matching",
  PAY: "/v1/Transactions/pay",
  COMFIRM_PAYMENT: "/v1/Transactions/confirm",

  ORDERS_METRICS_PENDING: "v1/Metrics/pending-order-count",
  ORDERS_METRICS_COMPLETE: "v1/Metrics/complete-order-count",
  DRIVER_METRICS: "v1/Metrics/driver-count",
  VEHICLE_METRICS: "v1/Metrics/vehicle-count",

  // Transactions
  GET_ALL_TRANSACTIONS: "v1/Transactions/view-transactions",
  ADMIN_METRICS: "/admin/v1/Metrics",
  GET_WALLET_BALANCE: "v1/Wallet/get-balance",
  VALIDATE_WALLET: "v1/Wallet/validate-account",
  NEW_WALLET: "v1/Wallet/new",
  MATTA_GET_BANKS: "v1/Bank/get-bank",
  CUSTOMER_PROFILE: "v1/Register/user-profile",

  GET_ADMIN_LEDGERS: "admin/v1/ledger/get-all",

  GET_LEDGERS: "v1/ledger/get-all",
  GET_PENDING_PAYMENTS: "v1/ShippingRequest/balance",

  // Flux

  INVITE_FLUX_ADMIN: "/admin/v1/users/invite-users",
  GET_FLUX_ADMINS: "/admin/v1/users/get-invites",
  REGISTER_FLUX_ADMIN: "/v1/onboarding/registeradmin",
  DELETE_FLUX_ADMIN: "/admin/v1/users/delete-invite",
  RESEND_FLUX_ADMIN_INVITE: "/admin/v1/users/resend-invite",

  // Currency
  GET_ALL_CURRENCIES: "v1/currency/get-all",
  ADD_CURRENCY: "v1/currency/all",

  GET_ALL_CURRENCY_RATE: "v1/currencyrate/get-all",
  ADD_CURRENCY_RATE: "v1/currencyrate/add",
  EDIT_CURRENCY_RATE: "v1/currencyrate/edit",
  DELETE_RATE: "v1/currencyrate/delete",
};
export default urls;
