import urls from "../helpers/url_helpers";
import {
  post,
  get,
  API_URL,
  put,
  walletGet,
  SSO_API_URL,
} from "../helpers/api_helpers";
import { cleanObject } from "@/utils/cleanObject.js";
import store from "../store";

const config = {
  headers: { Authorization: `Bearer ${store.getters.accessToken}` },
};
//Authentication

export async function inviteUsers(data) {
  return await post(urls.INVITE_USERS, data, config);
}

export async function deleteInvite(data) {
  return await post(
    `${urls.DELETE_INVITES}?invitationId=${data.invitationId}`,
    data,
    config
  );
}
export async function resendInvite(data) {
  return await post(`${urls.RESEND_INVITE_USERS}`, data, config);
}

export async function deleteUser(data) {
  return await post(`${urls.DELETE_USER}?email=${data.email}`, data, config);
}
export async function deleteAdmin(data) {
  return await post(`${urls.DELETE_ADMIN}`, data, config);
}
export async function changeUserRole(data) {
  return await post(urls.CHANGE_ROLE, data, config);
}
export async function getSubApps() {
  return await get(`${urls.GET_SUBAPPS}`, {}, SSO_API_URL);
}

export async function signUpWithMatta(data) {
  return await post(`${urls.SIGN_UP_WITH_MATTA}`, data, config);
}

export async function getInvites({
  Status,
  PageNumber = 1,
  PageSize = 10,
  Role,
  Search = "",
}) {
  return await get(
    `${urls.GET_INVITES}?Search=${Search}&Status=${Status}&Role=${Role}&PageNumber=${PageNumber}&PageSize=${PageSize}`,
    config
  );
}
export async function getRoles() {
  return await get(`${urls.GET_ROLES}`, config, API_URL);
}
export async function createcustomer(data) {
  return await post(urls.CUSTOMER_CREATE, data, config);
}

export async function getCustomers(payload) {
  return await get(
    `${urls.CUSTOMERS}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}
export async function getVendorInfo(id) {
  return await get(`${urls.GET_VENDOR_STORE}?sellerId=${id}`, config);
}
export async function updateVendorInfo(data) {
  return await post(`${urls.UPDATE_VENDOR_STORE}`, data, config);
}
export async function postStoreName(data) {
  return await get(
    `${urls.POST_VENDOR_STORE_NAME}?${new URLSearchParams(data)}`,
    config
  );
}
export async function customizeVendor(data) {
  return await post(`${urls.CUSTOMIZE_VENDOR_INFO}`, data, config);
}

// Addmin api
export async function adminGetMerchants(payload) {
  return await get(
    `${urls.ADMIN_GET_MERCHANT}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}
export async function adminGetMerchantCustomers(payload) {
  return await get(
    `${urls.ADMIN_GET_MERCHANT_CUSTOMERS}?${new URLSearchParams(
      cleanObject(payload)
    )}`,
    config
  );
}
export async function adminGetMerchantOrderRequest(payload) {
  return await get(
    `${urls.ADMIN_GET_MERCHANT_ORDER_REQUESTS}?${new URLSearchParams(
      cleanObject(payload)
    )}`,
    config
  );
}
export async function adminGetVendorOrderRequest(payload) {
  return await get(
    `${urls.ADMIN_GET_VENDOR_ORDER_REQUESTS}?${new URLSearchParams(
      cleanObject(payload)
    )}`,
    config
  );
}

export async function getVendorOrderRequest(payload) {
  return await get(
    `${urls.GET_VENDOR_ORDERS}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}
export async function adminGetVendors(payload) {
  return await get(
    `${urls.ADMIN_GET_VENDOR}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}

export async function adminGetDrivers(payload) {
  return await get(
    `${urls.ADMIN_GET_ALL_DRIVERS}?${new URLSearchParams(
      cleanObject(payload)
    )}`,
    config
  );
}
export async function adminGetTransactions(payload) {
  return await walletGet(
    `admin/${urls.GET_LEDGERS}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}
export async function adminGetVehicles(payload) {
  return await get(
    `${urls.ADMIN_GET_VEHICLE}?${new URLSearchParams(cleanObject(payload))}`,
    config
  );
}

export async function approveVehicle(payload) {
  return await post(
    `${urls.ADMIN_APPROVE_VEHICLE}`,
    cleanObject(payload),
    config
  );
}

export async function approveDriver(payload) {
  return await put(
    `${urls.ADMIN_APPROVE_DRIVER}/${payload.driverId}`,
    cleanObject(payload),
    config
  );
}

export async function getDeliveryCode() {
  return await post(`${urls.GET_DELIVERY_CODE}`, {}, config);
}

export async function upateOrderStatus(payload) {
  return await post(
    `${urls.VENDOR_ORDER_STATUS_CHANGE}`,
    cleanObject(payload),
    config
  );
}

export async function approveVendorOrder(payload) {
  return await post(
    `${urls.VENDOR_APPROVE_ORDER}`,
    cleanObject(payload),
    config
  );
}

export async function adminAssignVendor(payload) {
  return await post(
    `${urls.ADMIN_ASSIGN_VENDOR}`,
    cleanObject(payload),
    config
  );
}

export async function vendorAssignDriver(payload) {
  return await post(
    `${urls.VENDOR_ASSIGN_DRIVER}/${payload.vendorId}`,
    cleanObject(payload),
    config
  );
}

export async function vendorSendCode(payload) {
  return await post(
    `${urls.VENDOR_SEND_CODE}?${new URLSearchParams(payload)}`,
    cleanObject(payload),
    config
  );
}

export async function adminGetMetrics() {
  return await get(`${urls.ADMIN_METRICS}`, config);
}
export async function getAccountRoles(data) {
  return await post(urls.GET_ACCOUNT_ROLES, data, config, API_URL);
}
