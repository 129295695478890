<template>
  <div class="bg-[#FCFCFD]"><router-view></router-view></div>
</template>
<script setup>
import { setItem, getItem } from "@/utils/localStorageControl.js";
import { onMounted, provide, ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { getSubApps, getAccountRoles } from "@/services/userservices";
import { useStore } from "vuex";
import { AppCodes } from "@/utils/constants";

const store = useStore();
function getData() {
  getSubApps().then((res) => {
    if (res.status === 200) {
      const appList = res.data.data;
      setItem("appList", appList);
    }
  });
  if (store.getters.loggedUser) {
    // try {
    //   getAccountRoles({ email: store.getters.loggedUser.email }).then((res) => {
    //     if (res.status === 200) {
    //       console.log("🚀 ~ i ~ res:", res);
    //     }
    //   });
    // } catch (err) {
    //   console.log("🚀 ~ getData ~ err:", err);
    // }
  }
}
const selectedApp = ref(getItem("selectedApp") ?? AppCodes.matta);
function switchApp(value) {
  setItem("selectedApp", value);
  selectedApp.value = value;
}

onMounted(() => {
  getData();
});
provide("selectedApp", selectedApp);
provide("switchApp", switchApp);
</script>
<style>
label,
.input-label {
  color: #344054 !important;
  font-size: 14px;
  margin-bottom: 6px;
}
</style>
